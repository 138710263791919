.freePlaces {
    position: relative;

    em {
        font-size: 100%;
        display: inline-block;
        padding: 3px 5px;
        border-radius: 10px;
        font-style: normal;
        margin-left: 4px;
        background-color: rgba(0, 0, 0, 0.1);

        strong {
            font-weight: bold;
        }
    }

    .freePlacesContent {
        position: absolute;
        z-index: 110;
        top: 32px;
        left: 0;
        color: #000;

        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        background-color: #fff;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 7px 0;
                display: flex;
                white-space: nowrap;

                strong {
                    width: 50px;
                }

                & + li {
                    border-top: 1px solid #ccc;
                }
            }
        }
    }

    .actualFreePlaces {
        position: absolute;
        z-index: 100;
        top: 32px;
        left: -10px;
        color: #fff;
        white-space: nowrap;

        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        background-color: #0087dd;
    }
}

// Small screens
@media screen and (max-width: 600px) {
    .freePlaces {
        padding-bottom: 45px;

        .actualFreePlaces {
            margin-right: 20px;
            box-sizing: border-box;
            text-align: center;
        }
    }
}
