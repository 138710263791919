#startTeaser {
    width: 100%;
    max-height: 550px;
    overflow: hidden;

    position: relative;
    color: #fff;
    font-size: 70%;

    .logo-holder {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 75%;
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        margin-top: -40px;
        width: 100%;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

        img {
            max-width: 270px;
            width: 100%;
        }
    }

    .startTeaser-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: linear-gradient(
            to right,
            transparent 20%,
            rgba(184, 133, 85, 0.9) 100%
        );
    }

    img.background {
        width: 100%;
    }
}

// Small screens
@media screen and (max-width: 600px) {
    #startTeaser {
        font-size: 60%;

        .logo-holder {
            max-width: 190px;
            margin-top: 0;
            left: 70%;

            img {
                max-width: 150px;
            }
        }
    }
}
