div.pageNavigation {
    min-height: 300px;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('/assets/img/Background.jpg') no-repeat center center;
    background-size: cover;
    position: relative;

    .newOpening {
        font-size: 70%;
    }
}

// Small screens
@media screen and (max-width: 600px) {
    div.pageNavigation {
        display: none;
    }
}
