footer {
    margin-top: 40px;
    padding: 20px;
    background-color: #333;

    .logo {
        display: block;
        margin: 0 auto 20px auto;
        width: 100px;
    }

    section {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        text-align: center;
        color: #fff;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            li {
                padding: 10px;

                a {
                    color: #fff;
                }
            }
        }
    }
}
