div.title {
    .newOpening {
        font-size: 70%;
    }

    h1 {
        font-family: 'Neucha';
        font-size: 280%;
        line-height: 100%;
        color: #215a34;
        margin: 0 0 20px 0;

        display: flex;
        align-items: flex-end;

        .logoHolder {
            width: 63px;
            margin: 0 10px -3px 0;
            overflow: hidden;

            img {
                width: 160px;
            }
        }
    }
}

// Small screens
@media screen and (max-width: 600px) {
    div.title {
        h1 {
            font-size: 200%;
        }
    }
}
