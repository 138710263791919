@import '../../base';
.error {
    color: red;
}

form {
    fieldset {
        border: 0;
        padding: 0;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input,
        textarea,
        select {
            width: 100%;
            padding: 10px;
            outline: none;
            border: 1px solid #ccc;
            box-sizing: border-box;
            font-size: 110%;
            color: $darkgreen;
        }

        input[type='checkbox'] {
            width: auto;
            margin-right: 10px;
        }

        textarea {
            min-height: 100px;
        }

        & + fieldset {
            margin-top: 20px;
        }
    }
}
