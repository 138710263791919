@import 'base';

/* neucha-regular - latin */
@font-face {
    font-family: 'Neucha';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/neucha-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./assets/fonts/neucha-v12-latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/neucha-v12-latin-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('./assets/fonts/neucha-v12-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('./assets/fonts/neucha-v12-latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/neucha-v12-latin-regular.svg#Neucha')
            format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./assets/fonts/open-sans-v27-latin-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/open-sans-v27-latin-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('./assets/fonts/open-sans-v27-latin-regular.woff')
            format('woff'),
        /* Modern Browsers */
            url('./assets/fonts/open-sans-v27-latin-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/open-sans-v27-latin-regular.svg#OpenSans')
            format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    font-family: 'Open Sans';
    background-color: #d7f1df;
}

.inner {
    margin: 0 auto;
    max-width: 1000px;
}

.mt-1 {
    margin-top: 5px;
}
.mt-2 {
    margin-top: 10px;
}
.mt-3 {
    margin-top: 15px;
}
.mt-4 {
    margin-top: 20px;
}

.mb-1 {
    margin-bottom: 5px;
}
.mb-2 {
    margin-bottom: 10px;
}
.mb-3 {
    margin-bottom: 15px;
}
.mb-4 {
    margin-bottom: 20px;
}

.box {
    background-color: #fff;
    padding: 20px 270px 20px 20px;
    margin-top: -40px;
    box-sizing: border-box;
    position: relative;

    &.noPadding {
        padding-right: 20px;
    }

    & + .box {
        margin-top: 40px;
    }

    h3,
    h4,
    h5 {
        font-family: 'Neucha';
        color: $darkgreen;
        margin: 0 0 20px 0;
    }

    h3 {
        font-size: 200%;
    }

    h4 {
        font-size: 160%;
    }

    h5 {
        font-size: 120%;
    }

    a {
        color: $darkgreen;
    }

    p + h3,
    p + h4,
    h4 + h4 {
        margin-top: 40px;
    }

    .teaser {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 220px;
        z-index: 10;
        margin: -10px -10px 0 0;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .highlight,
    .info {
        font-weight: bold;
        color: $darkgreen;
        border-left: 5px solid $darkgreen;
        padding: 10px 10px 10px 15px;
        background-color: #d7f1df;
    }

    .info {
        border-color: #0087dd;
        background-color: #e1f3ff;
        color: #0087dd;
    }

    .openings span {
        display: inline-block;
        width: 140px;
    }
}

.news {
    padding: 0;
    margin: 0 0 20px 0;
    list-style: none;

    ul {
        margin-bottom: 30px;
    }
}

cite {
    background-color: #eee;
    border-radius: 15px;
    display: block;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    small {
        display: block;
        margin-top: 10px;
        font-style: normal;
    }
}

.button {
    display: inline-block;
    padding: 5px 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    font-size: 90%;
    border: 2px solid transparent;
    transition: all 0.5s ease-out;
    background-color: $darkgreen;
    color: #fff !important;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;

    svg {
        display: inline-block;
        width: 14px;
        fill: white;
        margin: 0 0 2px 4px;
    }

    &:hover {
        color: $darkgreen !important;
        background-color: transparent;
        border-color: $darkgreen;

        svg {
            fill: $darkgreen;
        }
    }

    &.simple {
        background-color: transparent;
        border: 2px solid $darkgreen;
        box-shadow: none;
        color: $darkgreen !important;

        &:hover {
            background-color: $darkgreen;
            color: #fff !important;
        }
    }

    &.white {
        background-color: #fff;
        color: $darkgreen !important;

        svg {
            fill: $darkgreen;
        }

        &:hover {
            border-color: #fff;
            background-color: transparent;
            color: #fff !important;

            svg {
                fill: white;
            }
        }
    }

    & + .button {
        margin-left: 20px;
    }
}

// Small screens
@media screen and (max-width: 700px) {
    .box {
        padding-right: 20px;
        margin-top: 0;

        .teaser {
            display: none;
        }
    }
}

// Very small screens
@media screen and (max-width: 500px) {
    .button {
        width: 100%;
        text-align: center;
    }
}
