@import '../../base';

.teaser {
    display: block;
    transition: margin 0.5s ease-out;
    text-decoration: none;
    color: $darkgreen;
    font-family: 'Neucha';
    text-transform: uppercase;

    img {
        border-radius: 50%;
        width: 100%;
        border: 4px solid #215a34;
        box-sizing: border-box;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        transition: all 0.5s ease-out;
    }

    &:not(.noLink) img {
        filter: grayscale(0.7);
    }

    strong {
        display: block;
        margin-top: 5px;
        text-align: center;

        &.inNavigation {
            background-color: rgba(0, 0, 0, 0.75);
            color: #fff;
            border-radius: 5px;
            padding: 5px;
        }
    }

    &:not(.noLink).active {
        img {
            border-color: #fff;
            filter: none;
        }

        strong.inNavigation {
            background-color: #fff;
            color: #000;
        }
    }

    &:hover:not(.noLink) {
        margin-top: -10px;

        img {
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
            margin-bottom: 10px;
            filter: none;
            border-color: #fff;
        }
    }
}
