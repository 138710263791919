@import '../../../base';

.menu {
    position: fixed;
    top: 22px;
    right: 13px;
    z-index: 100;

    input {
        display: none;
    }

    .naviToggle {
        position: relative;
        display: block;
        height: 30px;
        width: 30px;
        z-index: 105;
        text-align: center;
        cursor: pointer;
    }

    .background {
        height: 46px;
        width: 46px;
        border-radius: 50%;
        position: fixed;
        top: 13px;
        right: 5px;
        background-color: $darkgreen;
        z-index: 100;

        transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    }

    nav {
        height: 100vh;
        position: fixed;
        top: 0;
        right: -60vw;
        z-index: 102;
        opacity: 0;
        width: 0;
        transition: all 0.8s;
    }

    ul {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        list-style: none;
        text-align: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    li {
        display: block;
        margin: 20px;
    }

    a:link,
    a:visited {
        font-size: 120%;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover,
    a:active {
        color: lightgreen;
        cursor: pointer;
    }

    input:checked ~ .background {
        transform: scale(80);
    }

    input:checked ~ nav {
        opacity: 1;
        width: 100%;
        right: 0;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .icon,
    .icon::before,
    .icon::after {
        width: 24px;
        height: 2px;
        background-color: #fff;
        display: inline-block;
    }

    .icon::before,
    .icon::after {
        content: '';
        position: absolute;
        left: 0;
        transition: all 0.2s;
    }

    .icon::before {
        top: 8px;
    }

    .icon::after {
        top: -8px;
    }

    .button:hover icon:before {
        top: -1rem;
    }

    .button:hover icon::after {
        top: 1rem;
    }

    input:checked + .naviToggle .icon {
        background-color: transparent;
    }

    input:checked + .naviToggle .icon::before {
        top: 0;
        transform: rotate(135deg);
    }

    input:checked + .naviToggle .icon::after {
        top: 0;
        transform: rotate(-135deg);
    }
}
