.bigPhoto {
    margin-top: 40px;

    img {
        width: 100%;
    }
}

// Small screens
@media screen and (max-width: 600px) {
    .photos {
        ul {
            li {
                width: 100%;
            }
        }
    }
}
