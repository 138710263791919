ul.teaserList {
    list-style: none;
    padding: 0;
    margin: -60px 0 60px 0;

    position: relative;
    z-index: 10;

    display: flex;
    justify-content: center;

    li {
        width: 20%;
        padding: 0 10px;
    }

    &.inNavigation {
        margin: 0;

        li {
            width: 120px;
        }
    }
}

// Medium screens
@media screen and (max-width: 650px) {
    ul.teaserList {
        margin: 20px 0 60px 0;
        flex-wrap: wrap;

        li {
            box-sizing: border-box;
            width: 33%;
            padding: 10px;
        }
    }
}

// Small screens
@media screen and (max-width: 500px) {
    ul.teaserList {
        margin: 20px 0 60px 0;
        flex-wrap: wrap;
        justify-content: center;

        li {
            box-sizing: border-box;
            width: 50%;
            padding: 10px;
        }
    }
}
