@import '../../base';

header {
    background-color: $darkgreen;
    padding: 10px 20px;
    color: #fff;

    .header-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            width: 100px;
        }

        h3 {
            font-size: 90%;
            line-height: 160%;
            margin: 0 20px;
        }

        .freePlaces {
            font-size: 90%;
        }

        .button {
            margin-right: 50px;
        }
    }
}

// Small screens
@media screen and (max-width: 600px) {
    header {
        .header-holder {
            flex-wrap: wrap;
            flex-direction: column;

            h3 {
                text-align: center;
                margin: 10px 0;
            }

            .button {
                margin-right: 0;
                margin-top: 10px;
            }

            .freePlaces .actualFreePlaces {
                top: 43px;
                width: 220px;
            }
        }
    }
}
