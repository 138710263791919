.photos {
    ul {
        list-style: none;
        margin: 0 -10px 20px -10px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            box-sizing: border-box;
            width: 50%;
            padding: 10px;
            cursor: zoom-in;
            position: relative;

            .icon {
                position: absolute;
                top: 10px;
                right: 10px;
                display: block;
                padding: 5px;
                background-color: rgba(0,0,0,0.5);
                color: #fff;
                display: flex;

                svg {
                    width: 15px;
                    fill: #fff;
                    margin-right: 5px;
                }
            }

            img {
                width: 100%;
            }
        }
    }

    .modal {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.75);

        .icon {
            position: absolute;
            top: 10px;
            left: 10px;
            display: block;
            padding: 5px;
            background-color: rgba(0,0,0,0.5);
            color: #fff;
            display: flex;

            svg {
                width: 15px;
                fill: #fff;
                margin-right: 5px;
            }
        }

        .modalInner {
            margin: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: zoom-out;

            img {
                height: 100%;
                border: 4px solid #fff;
            }
        }
    }
}

// Small screens
@media screen and (max-width: 600px) {
    .photos {
        ul {
            li {
                width: 100%;
            }
        }
    }
}
